<template>
  <li
    ref="searchContainer"
    class="ucsc-navbar__item ucsc-navbar__item--hasSubnav"
    role="none"
    @mouseenter="isOpen = true"
    @mouseleave="isOpen = false"
  >
    <button
      ref="menuitem"
      class="ucsc-navbar__link"
      aria-haspopup="true"
      :aria-expanded="`${isOpen}`"
      :aria-controls="`submenu-${uid}`"
      @focus="handleFocusEvents"
      @blur="handleFocusEvents"
    >
      <span class="ucsc-navbar__iconWrapper">
        <SvgIcon name="search" :class="['ucsc-navbar__icon']" />
      </span>
      <span class="ucsc-navbar__label">Cerca nel sito</span>
    </button>
    <div
      :id="`submenu-${uid}`"
      ref="submenu"
      role="region"
      class="ucsc-navbar__subnavSearch"
    >
      <div class="bg-white w-full flex justify-center items-center h-24 py-2">
        <SearchAutocomplete
          class="h-full"
          @select="isOpen = false"
          @focus="handleFocusEvents"
          @blur="handleFocusEvents"
        />
      </div>
      <div class="flex-1 w-full bg-black/40" @click="isOpen = false"></div>
    </div>
  </li>
</template>

<script>
// Libs
import debounce from 'lodash/debounce'

// Mixins
import UniqueIdMixin from '~/mixins/unique-id'

// Components
import SearchAutocomplete from '~/components/SearchAutocomplete.vue'

export default {
  components: {
    SearchAutocomplete,
  },
  mixins: [UniqueIdMixin],

  data() {
    return {
      isOpen: false,
    }
  },

  watch: {
    isOpen(isOpenValue) {
      const { submenu } = this.$refs

      const method = isOpenValue ? 'add' : 'remove'

      submenu.classList[method]('ucsc-navbar__subnavSearch--isVisible')
    },
  },

  mounted() {
    // debounce needed to not close when passing focus from button to input and viceversa
    this.handleFocusEvents = debounce(this.handleFocusEvents, 50)
  },

  methods: {
    handleFocusEvents(event) {
      // When blur happens on option click both currentTarget and relatedTarget are null
      // ignoring the blur event because the selection is happening
      const { currentTarget, relatedTarget, type } = event
      if (!currentTarget && !relatedTarget && type === 'blur') return

      const { searchContainer } = this.$refs

      this.isOpen = searchContainer.contains(document.activeElement)
    },
  },
}
</script>
