<template>
  <nav class="ucsc-navbar" aria-label="Menù di navigazione">
    <div>
      <NuxtLink
        :to="$store.state.auth.loggedIn ? '/area-riservata/dashboard' : '/'"
        class="ucsc-header__logoInner"
        :title="
          $store.state.auth.loggedIn ? 'Dashboard area riservata' : 'Home'
        "
      >
        <!-- FIXME -->
        <!-- <Logo :size="$options.LogoSizes.Large" /> -->
        <img
          class="ucsc-logo--large"
          :src="`/assets/img/logo/ucsc-logo.svg`"
          alt="Logo UniCredit SubitoCasa"
          width="260"
          height="40"
        />
      </NuxtLink>
    </div>
    <div class="ucsc-navbar__start">
      <ul
        class="ucsc-navbar__list"
        role="menubar"
        aria-label="Menù di navigazione sezioni"
      >
        <NavItem tag="li" :item="$utilities.navigation.vendo" />
        <NavItem tag="li" :item="$utilities.navigation.cerco" />
        <NavItem tag="li" :item="$utilities.navigation.serviziDocumenti" />
        <NavItem tag="li" :item="$utilities.navigation.contatti" />
        <SearchRoutes />
      </ul>
    </div>
    <div class="ucsc-navbar__end">
      <AuthLoginIcon v-if="!$store.state.auth.loggedIn" />
      <template v-else>
        <LazyAuth />
        <LazyAuthMobile />
      </template>
    </div>
  </nav>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Components
import AuthLoginIcon from '~/components/AuthLoginIcon.vue'
import NavItem from '~/components/NavItem.vue'
import SearchRoutes from '~/components/SearchRoutes.vue'

const LazyAuthMobile = defineAsyncComponent(() =>
  import('~/components/AuthMobile.vue')
)
const LazyAuth = defineAsyncComponent(() => import('~/components/Auth.vue'))

export default {
  components: {
    AuthLoginIcon,
    NavItem,
    SearchRoutes,
    LazyAuth,
    LazyAuthMobile,
  },

  head() {
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: '/assets/img/logo/ucsc-logo.svg',
          fetchpriority: 'high',
        },
      ],
    }
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/nav';
</style>
